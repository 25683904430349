$('.info_slider .block').slick({
  dots: false,
  infinite: true,
  speed: 500,
  fade: true,
  cssEase: 'linear',
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: true,
  prevArrow: '<div class="prev"><i class="fa fa-angle-left"></i></div>',
  nextArrow: '<div class="next"><i class="fa fa-angle-right"></i></div>',
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
		arrows: true
      }
    },
    {
      breakpoint: 780,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
		dots: false,
		arrows: false
      }
    },
    {
      breakpoint: 560,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
		dots: true,
		arrows: false
      }
    }
  ]
});

$(function(){ 
	var buttons = $(".buttons");
	var calls = $(".calls_scroll");
	fimenu();
	
    function fimenu(e){
		var scrolltop = $(this).scrollTop();
		
		if(scrolltop > 100){
			buttons.slideDown();
			calls.show();
			//$(".navigation_container").addClass("navigation_height");
			
		}else{
			buttons.slideUp();
			calls.hide();
			//$(".navigation_container").removeClass("navigation_height");
			$(".menu_catalog").removeClass("opens");
			$(".overlay_content_from_menu").removeClass("open");
		}
	}
	
	$(document).on("scroll", fimenu);
	
	buttons.on("click", function(){
		$("html, body").animate({
			scrollTop: 0
		}, 2000);
	}); 
	
	$(document).on("scroll", fimenu);
	
	$(function() {
		var burgerMenu = $(".navbar-header .menu-trigger");
		var content = $(".burger-menu");
		
		burgerMenu.on("click", function(e){
			e.preventDefault();
			content.toggleClass("active");
			$(".overlay_content_from_menu").addClass("open");
		});
		
		$(".overlay_content_from_menu").on("click", function(e){
			$(".overlay_content_from_menu").removeClass("open");
			content.removeClass("active");
		});
	});
});

$("[data-fancybox]").fancybox({
	speed : 330,
	infobar : true,
	buttons : false,
	protect : true,
});
$(function () {
    $('.btn .salebtn').on('click', function (e) {
        e.preventDefault();
        var selector = $(this).attr('href');
        var h = $(selector);
        $('html, body').animate({
            scrollTop: h.offset().top - 100
        }, 400);
    });
});

$(function(){
	var context = $(this);
	context.section = $(".burger-menu > .navi > .nav > li");
	context.textSection = $(".burger-menu > .navi > .nav > .li > .dropdown");
	
	context.section.on("click", function(){
		context.section.removeClass("active").eq($(this).index()).addClass("active");
		context.textSection.hide().eq($(this).index()).show();
	});
});